<template>
<button :type="type" :class="{ secondary: isSecondaryColor }" :disabled="disabled" @click="$emit('click')">
    {{ text }}
</button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button'
        },
        text: {
            type: String,
        },

        isSecondaryColor: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['click']
}
</script>

<style lang="scss" scoped>
button {
    @include contained-button;

    &.secondary {
        background: #f1f1f1;
        color: black;

        .mdi-icon {
            color: $col_alpha;
        }

        &:hover {
            background: $col_alpha;
            color: white;

            .mdi-icon {
                color: white;
            }
        }
    }
}
</style>
